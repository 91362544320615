/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    uId
    phone
    firstName
    lastName
    enableNotification
    enableSms
    Activitys {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    uId
    phone
    firstName
    lastName
    enableNotification
    enableSms
    Activitys {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    uId
    phone
    firstName
    lastName
    enableNotification
    enableSms
    Activitys {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createActivity = /* GraphQL */ `mutation CreateActivity(
  $input: CreateActivityInput!
  $condition: ModelActivityConditionInput
) {
  createActivity(input: $input, condition: $condition) {
    activityName
    activityDescription
    activityDifficulty
    activityLocation
    activityType
    activityRepeatRule
    activitySharedUsers
    activityOwner
    activityStartDate
    activityEndDate
    activityStartTime
    activityEndTime
    user {
      uId
      phone
      firstName
      lastName
      enableNotification
      enableSms
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    userActivitysId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActivityMutationVariables,
  APITypes.CreateActivityMutation
>;
export const updateActivity = /* GraphQL */ `mutation UpdateActivity(
  $input: UpdateActivityInput!
  $condition: ModelActivityConditionInput
) {
  updateActivity(input: $input, condition: $condition) {
    activityName
    activityDescription
    activityDifficulty
    activityLocation
    activityType
    activityRepeatRule
    activitySharedUsers
    activityOwner
    activityStartDate
    activityEndDate
    activityStartTime
    activityEndTime
    user {
      uId
      phone
      firstName
      lastName
      enableNotification
      enableSms
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    userActivitysId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityMutationVariables,
  APITypes.UpdateActivityMutation
>;
export const deleteActivity = /* GraphQL */ `mutation DeleteActivity(
  $input: DeleteActivityInput!
  $condition: ModelActivityConditionInput
) {
  deleteActivity(input: $input, condition: $condition) {
    activityName
    activityDescription
    activityDifficulty
    activityLocation
    activityType
    activityRepeatRule
    activitySharedUsers
    activityOwner
    activityStartDate
    activityEndDate
    activityStartTime
    activityEndTime
    user {
      uId
      phone
      firstName
      lastName
      enableNotification
      enableSms
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    userActivitysId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivityMutationVariables,
  APITypes.DeleteActivityMutation
>;
