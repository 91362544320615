import { FunctionComponent } from "react";
import styles from "./EventEventDetailsNoAcc1.module.css";
import { Container } from "@material-ui/core";

const EventEventDetailsNoAcc1: FunctionComponent = () => {
  return (
    <Container maxWidth="lg">
    <div className={styles.eventEventDetailsNoAcc}>
  
      <div className={styles.eventEventDetailsNoAccChild}>
      <b className={styles.adventurePlanet}>Motus</b>
      <b className={styles.frommeShred}>{`Fromme Shred `}</b>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-location-pin.svg"
      />
 </div>
  <div>
  <div >Friday, March 10th</div>
  <div >1:00pm - 2:30pm</div>
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/sf-symbol--arrowclockwise.svg"
      />
 </div>
  <div>
  <div >Fromme</div>
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/sf-symbol--textformatsize.svg"
      />
 </div>
  <div>
  <div >Hosted by Rich</div>
  </div>
</div>
</div>
<div >
<b >4 people are invited</b>
<div>
<div className={styles.chipParent}>
        <div className={styles.chip}>
          <div className={styles.signUp}>Pending invite</div>
        </div>
        <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Brian Taylor</div>
        </div>
        <div className={styles.chip}>
          <div className={styles.signUp}>You</div>
        </div>
        <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Richie Scott</div>
        </div>
      </div>
</div>
</div>

<div>
<b className={styles.difficulty}>Difficulty</b>
<div className={styles.difficult}>Difficult</div>
</div>
<div>
  <div>
  <b className={styles.details}>Details</b>
  <div className={styles.p10}>
        Let’s go for a ride on Mt. Fromme and have a beer afterwards.
      </div>
  </div>
  <div className={styles.fullwidth}>
  <div className={styles.buttonPrimary}>
        <b className={styles.signUp}>Accept</b>
      </div>
  <div className={styles.buttonSecondary}>
        <div className={styles.signUp}>Decline</div>
      </div>
      
  </div>
</div>

      
      
     
    </div>
    </Container>
  );
};

export default EventEventDetailsNoAcc1;
