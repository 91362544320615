import { FunctionComponent, JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import styles from "./HomeFeed.module.css";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { generateClient } from "aws-amplify/api";
import { getUser, listActivities } from "../graphql/queries";
import AvailabilitySharedNoAccou from "./AvailabilitySharedNoAccou";
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { useNavigate } from "react-router-dom";



const client = generateClient();
const HomeFeed: FunctionComponent = () => {
  const [gridevent,setgridevent]:any = useState(false);
  const [signedUser,setSignedUser]:any = useState('');
  const [AllEvents,setAllEvents]:any = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchTodos()
   
  }, []);
  const fetchTodos = async () =>{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // const userData = await client.graphql({
    //   query:getUser,
    //   variables:{id:params.id}
    // })
    try {
      const todoData = await client.graphql({
        query: listActivities
          });
      //const todoData = await API.graphql(graphqlOperation(listActivities));
      const activityList = todoData?.data.listActivities.items;
      console.log(activityList);
      const dates: string[] = [];
      const activityData: any[] = [];
      for(var i=0;i<activityList.length;i++){
        let rule:any = []
        let rulesdatanew:any = [];
        let ownerId=JSON.parse(activityList[i].activityOwner);
        if(activityList[i].activityType == '2' && ownerId.id == '1c7da568-3071-70cb-938a-70b1aedcfe1b'){
          if(activityList[i].activityRepeatRule == ""){
         
            rulesdatanew.push(activityList[i].activityStartDate);
          }else{
            let ruleData=activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            console.log(ruleArray.all())
            rulesdatanew=ruleArray.all();
          }
        }
       

        // console.log(rulesdatanew)
        const rulesdata:any = []
        rulesdatanew.filter((data:any)=>{
          if(data !=''){
            data = new Date(data);
            const currentDate = new Date().getDate();
            const currentMonth = new Date().getMonth()+1;
            const currentYear = new Date().getFullYear();
            const newDate = data.getDate();
            const newMonth = data.getMonth()+1;
            const newYear = data.getFullYear();
            const fullCurrentdate = new Date(currentYear+"-"+currentMonth+"-"+currentDate);
            const fullNewDate = new Date(newYear+"-"+newMonth+"-"+newDate);
            // console.log(fullCurrentdate,fullNewDate)
            if(fullCurrentdate<=fullNewDate){
              rulesdata.push(data)
            }else{
            }
          }
          
  
        })
        // for(var k=0;k<rulesdatanew.length;k++){
          
        // }
        console.log(rulesdata)
        for(var j=0;j<rulesdata.length;j++){
          if(!dates.includes(rulesdata[j].toDateString())){
            // console.log(21)
            dates.push(rulesdata[j].toDateString())
            const data:any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList
  
            // if(activityList[i].activityRepeatRule!=""){
            //   data.date = rulesdata[j].toDateString()
            // }
            // else{
            //   data.date = activityList[i].activityStartDate.toDateString()
            // }
            activityData.push(data)
            
            // let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
            // // console.log("Shared Users",sharedUsers,activityList[i].activityRepeatRule)
            // if(activityList[i].activityRepeatRule!=""){
            //   console.log(31,signedUser,)
            //   if(signedUser != ''){
            //     if(signedUser.phone === JSON.parse(activityList[i].activityOwner).phone || (sharedUsers.some((el: { phone: any; }) => el.phone.replaceAll(" ","") === signedUser.phone)) ==true){
            //       console.log(1)
            //       activityData.push(data)
            //     }
            //   }
             
            // }
            // else
            // {
            //   console.log(2)
            //   activityData.push(data)
            // }
            
            //activityData.push(data)
  
          }else{
            console.log(3)
            for(var k=0;k<activityData.length;k++){
              if(rulesdata[j].toDateString() == activityData[k].date){
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityData[k].arrayList.push(activityList[i])
                // if(activityList[i].activityRepeatRule!=""){
                //   if(signedUser != ''){
                //     if(signedUser.phone === JSON.parse(activityList[i].activityOwner).phone || (sharedUsers.some((el: { phone: any; }) => el.phone.replaceAll(" ","") === signedUser.phone)) ==true){
                //       console.log(1)
                //       activityData[k].arrayList.push(activityList[i])
  
                //     }
                //   }
                // }
                // else{
                //   console.log(5)
                //   activityData[k].arrayList.push(activityList[i])
                // }
              }
              // else{
              //   activityData[k].arrayList.push(activityList[i])
              // }
            }
          }
        }
      }
      console.log(activityData)
      setAllEvents(activityData)
  }
  catch(error){
    console.log(error)
  }
  
}
  const courses = [

    {
      id: 0,
      name: 'Rich is available',
      time: '09:00 - 11:00'
  
    },
    {
      id: 1,
      name: 'hari is available',
      time: '09:00 - 11:00'
    },
    {
      id: 2,
      name: 'siva is available',
      time: '09:00 - 11:00'
    }
  ]

  const arrayDataItems =(data:any) => data.map((course:any) => 
   <>
   
   <a className={styles.cardItem}>
        <div className={styles.feed}>
        <div className={styles.eventGroup1}>
          <div className={styles.cardList}>
        <a  className={styles.cardItem}>
        <div className={styles.availabilityCard5}>
            <img
              className={styles.availabilityCardInner}
              alt=""
              src="/group-25.svg"
            />
            <div className={styles.pm400pm}>{course.activityStartTime}</div>
            <div className={styles.hostedBy2}>
              <div className={styles.hostedByItem} />
              <div className={styles.jodyWilliamsIsContainer}>
              {course?.activityName}
              </div>
            </div>
            <div className={styles.button6}>
              <div className={styles.jodyWilliamsIsContainer}>Edit</div>
            </div>
            <div className={styles.button3}>
              <div className={styles.jodyWilliamsIsContainer}>
                Schedule ride
              </div>
            </div>
          </div>
        </a>
   
      
    </div>
        </div>
      </div>
        </a>
     
   </>

  )

  const arr=['Sunday, March 19th','Monday, March 19th','Tuesday, March 19th'];

 function timeFormat(time:any){
    let timezone=new Date(time);
    return timezone.getHours()+":"+timezone.getMinutes();
  }

  const arrayData = AllEvents.map((course: any) => 
    <div>
        <div className={styles.leftAlign}>
        <b className={styles.jodyWilliamsIsContainer}>{course.date}</b>
        </div>
      <div>
    <div className={styles.cardList}>
    {course.arrayList.map((item:any)=>
     
        <>
        <a className={styles.cardItem}>
        <div className={styles.feed}>
        <div className={styles.eventGroup1}>
          <div className={styles.cardList}>
        <a  className={styles.cardItem}>
        <div className={styles.availabilityCard5}>
            <img
              className={styles.availabilityCardInner}
              alt=""
              src="/group-25.svg"
            />
            <div>
            <div className={styles.pm400pm}>{timeFormat(item.activityStartTime)}</div><div className={styles.pm400pm44}>-</div><div className={styles.pm400pm4}>{timeFormat(item.activityEndTime)}</div>
            </div>
            
            <div className={styles.hostedBy2}>
              <div className={styles.hostedByItem} />
              <div className={styles.jodyWilliamsIsContainer}>
              Available
              </div>
            </div>
            <div className={styles.button6}>
              <div className={styles.jodyWilliamsIsContainer}>Edit</div>
            </div>
            <div className={styles.button3}>
              <div className={styles.jodyWilliamsIsContainer}>
                Schedule ride
              </div>
            </div>
          </div>
        </a>
   
      
    </div>
        </div>
      </div>
        </a>
        </>
      
    )}
    
    {/* {arrayDataItems(course.arrayList)} */}
    </div>
    </div>
      </div>

  )



  return (
    <Container maxWidth="lg">
    
    <>{gridevent==true ? <AvailabilitySharedNoAccou  /> : <>
    <div className={styles.leftAlign}>
    <b >Motus</b>
   
      </div>
      <div className={styles.leftAlign}>
      <b className={styles.jodyWilliamsIsContainer}>Schedule</b>
    <span className={styles.filter}>
   
        <img  className={styles.iconListChild} alt="" src="/frame-20.svg" />
        <img onClick={()=>{setgridevent(true)}} className={styles.iconListChild} alt="" src="/frame-21.svg" />
        <button onClick={()=> {navigate("/event-event-details-no-account")}}>test</button>
     
    </span>
      </div>
    
{arrayData}


      <div>
      <div className={styles.cardOuter}>
    <div className={styles.card}>
    <div className={styles.container}>
    <div className={styles.cdAlign}> 
    
    <div className={styles.fl}> <div className={styles.messagesAvatar}>
          <img
            className={styles.contactPhotoIcon}
            alt=""
            src="/-contact-photo1@2x.png"
          />
        </div></div>
        <div className={styles.fr}><b className={styles.joinRichOn}><span className={styles.p8}>Join Rich on Motus</span></b></div>
    <div className={styles.cl}/>
    
</div>
<div className={styles.p10} >
  <div className={styles.alignCenter}>
  <span className={styles.alignCenter}>Sign up to Motus to schedule rides of your own.</span>  
  </div>
        
        </div>  
   <div className={styles.cdAlign}>
   <div className={styles.buttonPrimary}>
          <b className={styles.jodyWilliamsIsContainer}>Create account</b>
        </div>
   </div>
       
    </div>
</div>
</div>


      </div>
      
     
      </>}</>
   

     
   
    {/* </div> */}
    </Container>
  );
};

export default HomeFeed;
