/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    uId
    phone
    firstName
    lastName
    enableNotification
    enableSms
    Activitys {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      uId
      phone
      firstName
      lastName
      enableNotification
      enableSms
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getActivity = /* GraphQL */ `query GetActivity($id: ID!) {
  getActivity(id: $id) {
    activityName
    activityDescription
    activityDifficulty
    activityLocation
    activityType
    activityRepeatRule
    activitySharedUsers
    activityOwner
    activityStartDate
    activityEndDate
    activityStartTime
    activityEndTime
    user {
      uId
      phone
      firstName
      lastName
      enableNotification
      enableSms
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    userActivitysId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityQueryVariables,
  APITypes.GetActivityQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities(
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      activityName
      activityDescription
      activityDifficulty
      activityLocation
      activityType
      activityRepeatRule
      activitySharedUsers
      activityOwner
      activityStartDate
      activityEndDate
      activityStartTime
      activityEndTime
      id
      createdAt
      updatedAt
      userActivitysId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
