import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./NoAccountAcceptInviteE1.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { updateActivity } from "../graphql/mutations";

const client = generateClient()
const NoAccountAcceptInviteE1: FunctionComponent = () => {
  const [firstName,setFirstname] = useState('');
  const [lastName,setLastname] = useState('');
  const [phoneNumber,setPhoneNumber] = useState(0);
  const [activityId,setActivityId] = useState('');
  const [browserdata,setBrowserData]:any = useSearchParams();
  const [shareusersData,setshareusersData]:any =useState();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("demo",localStorage.getItem('SharedUsersData'))
    if(activityId =="" ){
      fetchTodos()
    }
  })

  async function fetchTodos() {
    let data=localStorage.getItem('SharedUsersData');
    let usersData= data !== null ?JSON.parse(data):'';
    console.log(usersData)
    setshareusersData(usersData);
    console.log(usersData)
    if(usersData.length > 0){
      
    let findbyPhoneno=usersData.find((val:any)=> val.phone == '+'+browserdata.get("phone").trim());
      setFirstname(findbyPhoneno.firstName);
      setLastname(findbyPhoneno.lastName);
      // setPhoneNumber(Number(browserdata.get("phone")));
    }
    try {
     let activityNewId:any = localStorage.getItem("activityId");
      setActivityId(activityNewId)
    } catch (err) {
      console.log('error fetching todos',err);
    }
  } 
  const onInputContainerClick = useCallback(() => {
    // Please sync "Sign Up / Create Account" to the project
  }, []);

  const onButtonPrimaryClick = async () => {
    console.log(firstName,lastName)
    let value=localStorage.getItem('SharedUsersData');
    let localUserData= value !== null ?JSON.parse(value):'';
    let updateFLname=localUserData.map((val:any)=>{
      if(val.phone == '+'+browserdata.get("phone").trim()){
        console.log("inside","dd",firstName,lastName)
        val.firstName=firstName;
        val.lastName=lastName;
        return val
      }else{
        return val
      }
    });
    
    let updateActivityData = {
      id :browserdata.get("eventId"),
      activitySharedUsers: JSON.stringify(updateFLname)
    }
    console.log(updateActivityData)
    const todoData:any = await client.graphql({
      query: updateActivity,
      variables:  {input:updateActivityData}
    });
    navigate("/event-event-details-no-account")
    // Please sync "Sign Up / Create Account" to the project
  };

  const onArrowBackTextClick = useCallback(() => {
    navigate("/event-event-details-browser1?id="+activityId)
    // Please sync "Sender / Create Event / Details  Filled" to the project
  }, []);
  const handleFirstName = (event:any) =>{
    console.log(event.target.value)
    setFirstname(event.target.value);
    console.log(firstName)
  }
  const handleLastName = (event:any) =>{
    setLastname(event.target.value)
  }
  const handlePhoneNumber = (event:any) =>{
    setPhoneNumber(event.target.value)
  }
  return (
    <div className={styles.noAccountAcceptInviteE}>
      <div className={styles.form}>
        <div className={styles.input}>
          <div className={styles.label}>
            <b className={styles.firstName}>First name</b>
          </div>
          <div className={styles.input1} onClick={onInputContainerClick}>
            {/* <img
              className={styles.icoutlineLocalPhoneIcon}
              alt=""
              src="/icoutlinelocalphone.svg"
            />
            <div className={styles.firstName}>First name</div> */}
            
            <input style={{backgroundColor:"whitesmoke",border:"none",width:"100%",padding:"2%"}} value={firstName} onChange={handleFirstName} type="text" placeholder="First Name" />
        
          </div>
          
        </div>
        <div className={styles.input}>
          <b className={styles.firstName}>Last name</b>
          <div className={styles.input3}>
            <input style={{backgroundColor:"whitesmoke",border:"none",width:"100%",padding:"2%"}} value={lastName} onChange={handleLastName} type="text" placeholder="Last Name" />
          </div>
        </div>
        {/* <div className={styles.input}>
          <b className={styles.firstName}>Phone number</b>
          <div className={styles.input3}>
            <input style={{backgroundColor:"whitesmoke",border:"none",width:"100%",padding:"2%"}} value={phoneNumber} onChange={handlePhoneNumber} type="number" placeholder="Phone Number" />
          </div>
        </div> */}
      </div>
    
      <div className={styles.buttonPrimary} style={{backgroundColor:firstName !=''&&lastName !=""?"#00BE9A":""}} onClick={onButtonPrimaryClick}>
        <b className={styles.firstName}>Accept</b>
      </div>
      <b className={styles.acceptInvite}>Motus</b>
      <div className={styles.enterYourFirst}>
      Sign up with Adventure Planet for free
      </div>
      <div className={styles.arrowBack} onClick={onArrowBackTextClick}>
        arrow_back
      </div>
    </div>
  );
};

export default NoAccountAcceptInviteE1;
