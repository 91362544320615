import { FunctionComponent, useEffect, useState } from "react";
import styles from "./AvailabilitySharedNoAccou.module.css";
import { Container } from "@material-ui/core";
import HomeFeed from "./HomeFeed";
import { Calendar, Whisper, Popover, Badge } from 'rsuite';
import { generateClient } from "aws-amplify/api";
import { getUser, listActivities } from "../graphql/queries";
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'

const client = generateClient();

const AvailabilitySharedNoAccou: FunctionComponent = (message) => {
  const [filterevent,setfilterevent]:any = useState(false);
  const [arrayEvent,setarrayEvent]:any = useState([]);

  useEffect(() => {
    fetchTodos()
   
  }, []);

  
  const fetchTodos = async () =>{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // const userData = await client.graphql({
    //   query:getUser,
    //   variables:{id:params.id}
    // })
    try {
      const todoData = await client.graphql({
        query: listActivities
          });
      //const todoData = await API.graphql(graphqlOperation(listActivities));
      const activityList = todoData?.data.listActivities.items;
      console.log(activityList);
      const dates: string[] = [];
      const activityData: any[] = [];
      for(var i=0;i<activityList.length;i++){
        let rule:any = []
        let rulesdatanew:any = [];
        let ownerId=JSON.parse(activityList[i].activityOwner);
        if(activityList[i].activityType == '2' && ownerId.id == '1c7da568-3071-70cb-938a-70b1aedcfe1b'){
          if(activityList[i].activityRepeatRule == ""){
         
            rulesdatanew.push(activityList[i].activityStartDate);
          }else{
            let ruleData=activityList[i].activityRepeatRule as string;
            const ruleArray = RRule.fromString(ruleData);
            console.log(ruleArray.all())
            rulesdatanew=ruleArray.all();
          }
        }
       

        // console.log(rulesdatanew)
        const rulesdata:any = []
        rulesdatanew.filter((data:any)=>{
          if(data !=''){
            data = new Date(data);
            const currentDate = new Date().getDate();
            const currentMonth = new Date().getMonth()+1;
            const currentYear = new Date().getFullYear();
            const newDate = data.getDate();
            const newMonth = data.getMonth()+1;
            const newYear = data.getFullYear();
            const fullCurrentdate = new Date(currentYear+"-"+currentMonth+"-"+currentDate);
            const fullNewDate = new Date(newYear+"-"+newMonth+"-"+newDate);
            // console.log(fullCurrentdate,fullNewDate)
            if(fullCurrentdate<=fullNewDate){
              rulesdata.push(data)
            }else{
            }
          }
          
  
        })

        console.log(rulesdata)
        for(var j=0;j<rulesdata.length;j++){
          if(!dates.includes(rulesdata[j].toDateString())){
            // console.log(21)
            dates.push(rulesdata[j].toDateString())
            const data:any = {}
            const arrayList = []
            arrayList.push(activityList[i])
            data.date = rulesdata[j].toDateString()
            data.arrayList = arrayList
  

            activityData.push(data)

          }else{
            console.log(3)
            for(var k=0;k<activityData.length;k++){
              if(rulesdata[j].toDateString() == activityData[k].date){
                let sharedUsers = JSON.parse(activityList[i].activitySharedUsers)
                activityData[k].arrayList.push(activityList[i])
               
              }
              
            }
          }
        }
      }
      // console.log(activityData);
      setarrayEvent(activityData)
  }
  catch(error){
    console.log(error)
  }
  
}

  function getTodoList(date:any) {

    console.log(arrayEvent);
    // const dat=new Date();
    const day = date.getDate();
    const month = date.getMonth();
    let datedata=arrayEvent.find((v:any)=>{ console.log("ddd",day == new Date(v.date).getDate()); if(day == new Date(v.date).getDate() && month == new Date(v.date).getMonth()){return v} })
    
    if(datedata != undefined){
      console.log(day,month,new Date(datedata.date).getDate(),day == new Date(datedata.date).getDate())
      switch (day) {
        case new Date(datedata.date).getDate():
          console.log("inside")
          let valueData=datedata.arrayList.map((s:any)=>{ return{"time":s.time = "i'm Available",color:'#0066ff'}})[0];
          return [valueData]
        default:
          return [];
      }
    }
    else{
      return [];
    }


    
  }

  function timeFormat(time:any){
    let timezone=new Date(time);
    return timezone.getHours()+":"+timezone.getMinutes();
  }

  function renderCell(date:any) {
    const list = getTodoList(date);
    console.log(list)
    const displayList = list.filter((item, index) => index < 3);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li >
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <Popover>
                {list.map((item, index) => (
                  <p key={index}>
                    <b>{item.time}</b>
                  </p>
                ))}
              </Popover>
            }
          >
            <a>{moreCount} more</a>
          </Whisper>
        </li>
      );

      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li style={{fontSize: '9px'}} key={index}>
              <Badge style={{backgroundColor: item.color}} /> <b>{item.time}</b>
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

 
  
  return (
    // <Container maxWidth="lg">
      <>
      {filterevent == true ? <HomeFeed/>:<div className={styles.availabilitySharedNoAccou}>
      
      <div className={styles.cardCta}>
        <div className={styles.cardCtaChild} />
        <div className={styles.buttonPrimary}>
          <b className={styles.label}>Create account</b>
        </div>
        <b className={styles.joinRichOn}>Join Rich on Motus</b>
        <div className={styles.messagesAvatar}>
        
        </div>
        <div className={styles.signUpTo}>
          Sign up to Motus to schedule rides of your own.
        </div>
      </div>
      
      
      
      <div className={styles.datePicker}>
        <>
        <style>
        {`
      .bg-gray {
        background-color: rgba(242, 242, 242, 0.3);
      }
      .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
        width: 100%;
        height: 100px;
        overflow: hidden;
    }
    .rs-calendar-bordered .rs-calendar-table {
    border: 0px solid #e5e5ea; 
    /* border: 1px solid var(--rs-border-primary); */
    border-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 55px;
  // width: 55px;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  // color: #fff;
  color: #3498ff;
  width: 20px;
  height: 20px;
  background-color: #f8f8f8 !important;
  /* background-color: var(--rs-calendar-today-bg); */
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: 0 auto !important;
}
.rs-calendar-header-month-toolbar {
  float: none;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
  display: none;
}

      `}
      </style>
        <Calendar compact bordered cellClassName={(date) => (date.getDay() % 2 ? 'bg-gray' : undefined)}  renderCell={renderCell} />
        </>
      
      </div>
  
      <div className={styles.leftAlign}>
    <b >Motus</b>
      </div>

     <div className={styles.leftAlign}>
      <b className={styles.jodyWilliamsIsContainer}>Schedule</b>
    <span className={styles.flr}>
   
        <img onClick={()=>{setfilterevent(true)}} className={styles.iconListChild} alt="" src="/frame-20.svg" />
        <img className={styles.iconListChild} alt="" src="/frame-21.svg" />
     
    </span>
      </div>
    </div>}
    </>
    // </Container>
  );
};

export default AvailabilitySharedNoAccou;
