import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import NoAccountAcceptInviteE from "./pages/NoAccountAcceptInviteE";
import HomeFeed from "./pages/HomeFeed";
import DeleteAccount from "./pages/DeleteAccount";

import NoAccountAcceptInviteE1 from "./pages/NoAccountAcceptInviteE1";
import EventEventDetailsBrowse from "./pages/EventEventDetailsBrowse";
import EventEventDetailsBrowse1 from "./pages/EventEventDetailsBrowse1";
import AvailabilitySharedNoAccou from "./pages/AvailabilitySharedNoAccou";
import EventEventDetailsNoAcc from "./pages/EventEventDetailsNoAcc";
import EventEventDetailsNoAcc1 from "./pages/EventEventDetailsNoAcc1";
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

Amplify.configure(config);
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/home-feed":
        title = "";
        metaDescription = "";
        break;
      case "/no-account-accept-invite-enter-name-disabled":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-browser":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-browser1":
        title = "";
        metaDescription = "";
        break;
      case "/availability-shared-no-account":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-no-account":
        title = "";
        metaDescription = "";
        break;
      case "/event-event-details-no-account1":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      {/* <Route path="/" element={<DeleteAccount />} /> */}
      <Route path="/" element={<HomeFeed />} />
      <Route
        path="/no-account-accept-invite-enter-name-disabled"
        element={<NoAccountAcceptInviteE1 />}
      />
      <Route
        path="/event-event-details-browser"
        element={<EventEventDetailsBrowse />}
      />
      <Route
        path="/event-event-details-browser1"
        element={<EventEventDetailsBrowse1 />}
      />
      <Route
        path="/availability-shared-no-account"
        element={<AvailabilitySharedNoAccou />}
      />
      <Route
        path="/event-event-details-no-account"
        element={<EventEventDetailsNoAcc />}
      />
      <Route
        path="/event-event-details-no-account1"
        element={<EventEventDetailsNoAcc1 />}
      />
    </Routes>
  );
}
export default App;
