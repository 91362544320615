import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import styles from "./EventEventDetailsNoAcc.module.css";
import { useNavigate } from "react-router-dom";
import ApiCalendar from 'react-google-calendar-api';
const config = {
  "clientId": "484432091488-li5cev1qagfvc2147vjm50sivk7jcdsj.apps.googleusercontent.com",
  "apiKey": "AIzaSyCEQWiK5pI8l0sB6OhLdUw_nPPgR3GIog0",
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)
const EventEventDetailsNoAcc: FunctionComponent = () => {
  const [activityData,setActivityData]:any = useState('');
  const [activityId,setActivityId]:any = useState('');
  const [events, setEvents] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    console.log("demo")
    if(!activityData || activityData =="" || activityData == undefined){
      fetchTodos()
    }
  })

  const handleItemClick = (event: SyntheticEvent<any>, name: string): void => {
    if (name === 'sign-in') {
      apiCalendar.handleAuthClick()
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
    }
  };
  
  async function fetchTodos() {
    try {
      let activityNewData:any = localStorage.getItem("activityData");
      let activityNewId:any = localStorage.getItem("activityId");
      console.log(activityNewData)
      let activityJsonData :any = JSON.parse(activityNewData);
      let activityOwnerData = JSON.parse(activityJsonData.activityOwner)
      activityJsonData.activityOwner = activityOwnerData
      setActivityData(activityJsonData)
      setActivityId(activityNewId)
    } catch (err) {
      console.log('error fetching todos',err);
    }
  } 

  const addToGoogleCalender = () =>{
    apiCalendar.handleAuthClick()

    const eventFromNow: object = {
      summary: "Poc Dev From Now",
      time: 480,
    };
    
    apiCalendar
      .createEventFromNow(eventFromNow)
      .then((result: object) => {
        console.log(result);
      })
      .catch((error: any) => {
        console.log(error);
      });  }
  
  const backToEventDetails = () =>{
    navigate("/event-event-details-browser1?id="+activityId)
  }
  const goToAppDownload = () =>{
    // const ua = navigator.userAgent.toLowerCase()
    //   const isAndroid = ua.includes('android')
    //   if(isAndroid==true){
    //     window.location.href="https://play.google.com/store/apps/details?id=com.whatsapp&pcampaignid=web_share"
    //   }
    //   console.log(`The user is on an Android: ${isAndroid}`)
    //   const isIPhone:any = (navigator.userAgent.match(/iPhone/i)) ||(navigator.userAgent.match(/iPod/i))
    //   if(isIPhone == "iPhone"){
    //     window.location.href="https://apps.apple.com/in/app/whatsapp-messenger/id310633997"
    //   }
    //   console.log(`The user is on an iPhone: ${isIPhone}`)

  }
  return (
    <div className={styles.eventEventDetailsNoAcc}>
      <div className={styles.wellLetRichContainer}>
        <span>We’ll let {activityData?.activityOwner?.firstName} {activityData?.activityOwner?.lastName} know you are going to </span>
        <b>{activityData.activityName}</b>
        <span> on {new Date(activityData.activityStartDate).toDateString()} - {new Date(activityData.activityEndDate).toDateString() } {new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }.</span>
      </div>
      
      <div className={styles.eventEventDetailsNoAccChild} />
      <div className={styles.header}>
        <div className={styles.check}>check</div>
        <b className={styles.youreAllSet}>You’re all set!</b>
      </div>
     
      <div className={styles.button} onClick={backToEventDetails}>
        <div className={styles.check}>Back to event details</div>
      </div>
      <div className={styles.button1} onClick={addToGoogleCalender}>
        <div className={styles.check}>Add to Google calendar</div>
      </div>
      <div className={styles.cardCta}>
        <div className={styles.cardCtaChild} />
        <div className={styles.buttonPrimary} onClick={goToAppDownload}>
          <b className={styles.check}>Create account</b>
        </div>
        <b className={styles.joinRichOn}>Join {activityData?.activityOwner?.firstName} {activityData?.activityOwner?.lastName} on Motus</b>
        <div className={styles.messagesAvatar}>
          <img
            className={styles.contactPhotoIcon}
            alt=""
            src="/-contact-photo1@2x.png"
          />
        </div>
        <div className={styles.signUpTo}>
          Sign up to Motus to schedule rides of your own.
        </div>
      </div>
    </div>
  );
};

export default EventEventDetailsNoAcc;
