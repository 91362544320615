import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./EventEventDetailsBrowse.module.css";
import { Card, Container } from "@material-ui/core";
import { generateClient } from 'aws-amplify/api';
import { updateActivity } from "../graphql/mutations";
import { getActivity } from "../graphql/queries";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { getUser } from "../src/graphql/querie";

const client = generateClient()
const EventEventDetailsBrowse: FunctionComponent = () => {
  const [activityData,setActivityData]:any = useState('');
  const [firstName,setFirstname] = useState('');
  const [lastName,setLastName] = useState('');
  const [browserdata,setBrowserData]:any = useSearchParams();
  const [accepted,setAccepted] = useState('');
  const navigate = useNavigate();
  console.log("demo",browserdata.get("id"))
  useEffect(() => {
    console.log("demo")
    if(!activityData || activityData =="" || activityData == undefined){
      fetchTodos()
    }
  })
  async function fetchTodos() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log(urlSearchParams,params)
    if(params.redirect == 'invite'){
      navigate('/webapp')
    }
    else if(params.userId){
      navigate('/event-event-details-no-account1?userId='+params.userId)
    }
    try {
      let AcceptedData:any = localStorage.getItem("Accepted")
      setAccepted(AcceptedData)
      console.log("demo")
      const todoData:any = await client.graphql({
        query: getActivity,
        variables:  {id:browserdata.get("id")}
      });
      console.log(todoData)
      let activityData:any = todoData.data.getActivity;
      let sharedUsers:any =  JSON.parse(activityData.activitySharedUsers);
      let activityOwner:any = JSON.parse(activityData.activityOwner);
      activityData.activitySharedUsers = sharedUsers;
      console.log(activityData)
      setFirstname(activityOwner.firstName)
      setLastName(activityOwner.lastName)
      setActivityData(activityData)
      localStorage.setItem("activityData",JSON.stringify(activityData))
      localStorage.setItem("activityId",browserdata.get("id"))

      // const todos = todoData.data.lis.items;
      // setTodos(todos);
    } catch (err) {
      console.log('error fetching todos',err);
    }
  } 
  const goToName = async() =>{

    for(var i=0;i<activityData.activitySharedUsers.length;i++){
      if(activityData.activitySharedUsers[i].status != "Accepted"){
        activityData.activitySharedUsers[i].status = "Accepted"
      }
    }
    let updateActivityData = {
      id :browserdata.get("id"),
      activitySharedUsers: JSON.stringify(activityData.activitySharedUsers)
    }
    const todoData:any = await client.graphql({
      query: updateActivity,
      variables:  {input:updateActivityData}
    });
    localStorage.setItem("Accepted","Accepted")
    navigate('/no-account-accept-invite-enter-name-disabled')
  }
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container maxWidth="lg">
    <div className={styles.eventEventDetailsNoAcc}>
  
      <div className={styles.eventEventDetailsNoAccChild}>
      <b className={styles.adventurePlanet}>Motus</b>
      <b className={styles.frommeShred}>{activityData?.activityName}</b>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-location-pin.svg"
      />
 </div>
  <div>
  <div >{activityData.activityLocation}</div>
  
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-time.svg"
      />
 </div>
  <div>
  <div >{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }</div>
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-person.svg"
      />
 </div>
  <div>
  <div >Hosted by {firstName} {lastName}</div>
  </div>
</div>
</div>
<div >
<b >4 people are invited</b>
<div>
<div className={styles.chipParent}>
        <div className={styles.chip}>
          <div className={styles.signUp}>Pending invite</div>
        </div>
        <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Brian Taylor</div>
        </div>
        <div className={styles.chip}>
          <div className={styles.signUp}>You</div>
        </div>
        <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Richie Scott</div>
        </div>
      </div>
</div>
</div>

<div>
<b className={styles.difficulty}>Difficulty</b>
<div className={styles.difficult}>Difficult</div>
</div>
<div>
  <div>
  <b className={styles.details}>Going</b>
  <div className={styles.p10}>
        Let’s go for a ride on Mt. Fromme and have a beer afterwards.
      </div>
  </div>
  <div className={styles.fullwidth}>
  <div className={styles.buttonPrimary} onClick={() => setIsOpen(true)}>
        <b className={styles.signUp}>Yes</b>
      </div>
  <div className={styles.buttonSecondary}>
        <div className={styles.signUp}>No</div>
      </div>
      
  </div>
</div>

      
      
     
    </div>
    <>
      {isOpen ? (
      <Card >
      <div className={styles.menu}>
        <img className={styles.menuChild} alt="" src="/rectangle-41.svg" />
        <div className={styles.selected}>
          <div className={styles.frameParent}>
            <img className={styles.ellipseParent} alt="" src="/frame-1.svg" />
            <div className={styles.event}>Motus App</div>
          </div>
        </div>
        <div className={styles.selected1}>
          <div className={styles.frameParent}>
            <img
              className={styles.bibrowserChromeIcon}
              alt=""
              src="/bibrowserchrome.svg"
            />
            <div className={styles.event}>Chrome</div>
          </div>
        </div>
        <div className={styles.homeIndicatordark}>
          <div className={styles.homeIndicator} />
        </div>
        <div className={styles.button1}>
          <div className={styles.no}>Get app</div>
        </div>
        <div className={styles.button2}>
          <div className={styles.no}>Continue</div>
        </div>
        <div className={styles.header}>
          <b className={styles.no}>See this invite in...</b>
          <div className={styles.close} onClick={() => setIsOpen(false)}>
            close
          </div>
        </div>
      </div>
    </Card>
      ):''}
      </>
    </Container>
  );
};

export default EventEventDetailsBrowse;
