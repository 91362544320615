import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./EventEventDetailsBrowse1.module.css";
import { Container } from "@material-ui/core";
import { generateClient } from 'aws-amplify/api';
import { updateActivity } from "../graphql/mutations";
import { getActivity } from "../graphql/queries";
import { useNavigate, useSearchParams } from "react-router-dom";

const client = generateClient()
const EventEventDetailsBrowse1: FunctionComponent = () => {
  const [activityData,setActivityData]:any = useState('');
  const [firstName,setFirstname] = useState('');
  const [lastName,setLastName] = useState('');
  const [browserdata,setBrowserData]:any = useSearchParams();
  const [accepted,setAccepted] = useState('');
  const navigate = useNavigate();
  console.log("demo",browserdata.get("id"),browserdata.get("phone"))
  useEffect(() => {
    console.log("demo")
    if(!activityData || activityData =="" || activityData == undefined){
      fetchTodos()
    }
  })
  async function fetchTodos() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log(urlSearchParams,params)
    if(params.redirect == 'invite'){
      navigate('/webapp')
    }
    else if(params.userId){
      navigate('/event-event-details-no-account1?userId='+params.userId)
    }
    try {
      let AcceptedData:any = localStorage.getItem("Accepted");
      setAccepted(AcceptedData)
      console.log("demo",client,AcceptedData,browserdata.get("id"))
      const todoData:any = await client.graphql({
        query: getActivity,
        variables:  {id:browserdata.get("id")}
      });
      console.log(todoData)
      let activityData:any = todoData.data.getActivity;
      let sharedUsers:any =  JSON.parse(activityData.activitySharedUsers);
      let activityOwner:any = JSON.parse(activityData.activityOwner);
      activityData.activitySharedUsers = sharedUsers;
      console.log(activityData)
      setFirstname(activityOwner.firstName)
      setLastName(activityOwner.lastName)
      setActivityData(activityData)
      localStorage.setItem("activityData",JSON.stringify(activityData))
      localStorage.setItem("activityId",browserdata.get("id"))

      // const todos = todoData.data.lis.items;
      // setTodos(todos);
    } catch (err) {
      console.log('error fetching todos',err);
    }
  } 
  const goToName = async() =>{

    for(var i=0;i<activityData.activitySharedUsers.length;i++){
      if(activityData.activitySharedUsers[i].status != "Accepted"){
        activityData.activitySharedUsers[i].status = "Accepted"
      }
    }

    if(activityData.activitySharedUsers.length > 0){
      let findbyPhoneno=activityData.activitySharedUsers.find((val:any)=> val.phone == '+'+browserdata.get("phone").trim());
      console.log(findbyPhoneno,browserdata.get("phone").trim())
      if(findbyPhoneno){
        localStorage.setItem("SharedUsersData",JSON.stringify(activityData.activitySharedUsers));
        navigate('/no-account-accept-invite-enter-name-disabled?eventId='+browserdata.get("id")+'&phone='+findbyPhoneno.phone)
      }
      
    }

    // let updateActivityData = {
    //   id :browserdata.get("id"),
    //   activitySharedUsers: JSON.stringify(activityData.activitySharedUsers)
    // }
    // const todoData:any = await client.graphql({
    //   query: updateActivity,
    //   variables:  {input:updateActivityData}
    // });
    localStorage.setItem("Accepted","Accepted")
    
    // navigate('/no-account-accept-invite-enter-name-disabled?fname='+params.userId)
  }
  return (
    <Container maxWidth="lg">
    <div className={styles.eventEventDetailsNoAcc}>
  
      <div className={styles.eventEventDetailsNoAccChild}>
      <b className={styles.adventurePlanet}>Motus</b>
      <b className={styles.frommeShred}>{activityData?.activityName}</b>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-time.svg"
      />
 </div>
  <div>
  <div >{new Date(activityData.activityStartDate).toDateString()} - {new Date(activityData.activityEndDate).toDateString() }</div>
  <div >{new Date(activityData.activityStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(activityData.activityEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }</div>
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-location-pin.svg"
      />
 </div>
  <div>
   <div>{activityData?.activityLocation}</div>
  </div>
</div>

<div className={styles.p10}>
<div className={styles.datecard}>
  <img
        className={styles.iconLocationPin}
        alt=""
        src="/-icon-person.svg"
      />
 </div>
  <div>
  <div >Hosted by {firstName} {lastName}</div>
  </div>
</div>
</div>
<div style={{padding:'20px'}}>
<div >
{activityData.activitySharedUsers?(
        <>
              <b className={styles.peopleAreInvited}>{activityData?.activitySharedUsers.length} people are invited</b>
        </>
      ):(
        <>
              <b className={styles.peopleAreInvited}>0 people are invited</b>
        </>
      )
    }
<div>
<div className={styles.chipParent}>
        <div className={styles.chip}>
          <div className={styles.signUp}>Pending invite</div>
        </div>
        {(activityData.activitySharedUsers&&activityData.activitySharedUsers.length>0)?(
          <>
            {activityData?.activitySharedUsers.map((r:any,_index: any) =>
              <div className={styles.chip}>
              <div className={styles.ellipseParent}>
                <div className={styles.groupChild} />
                <div className={styles.check}>✓</div>
              </div>
              <div className={styles.brianTaylor}>{r.firstName} {r.lastName}</div>
            </div>
            )}
            </>
            ):null
            
            }
        {/* <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Brian Taylor</div>
        </div>
        <div className={styles.chip}>
          <div className={styles.signUp}>You</div>
        </div>
        <div className={styles.chip1}>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <div className={styles.check}>✓</div>
          </div>
          <div className={styles.brianTaylor}>Richie Scott</div>
        </div> */}
      </div>
</div>
</div>

<div>
<b className={styles.difficulty}>Difficulty</b>
<div className={styles.difficult}>{activityData?.activityDifficulty}</div>
</div>
<div>
  <div>
  <b className={styles.details}>Details</b>
  <div className={styles.plr10}>
  {activityData?.activityDescription}

      </div>
  </div>
  <div style={{width:"100%",marginLeft:"5%",marginRight:"5%"}}>
  <div className={styles.buttonPrimary} style={{backgroundColor:accepted != ""?"#00BE9A":""}} onClick={goToName}>
          <b className={styles.no}>Accept</b>
        </div> 

        <div className={styles.buttonDeclainalign} >
          <b className={styles.no}>Decline</b>
        </div> 
        {/* <div className={styles.button}>
          <div className={styles.no}>Decline</div>
        </div> */}

        
      </div>
  {/* <div className={styles.fullwidth}>
  <div className={styles.buttonPrimary}>
        <b className={styles.signUp}>Yes</b>
      </div>
  <div className={styles.buttonSecondary}>
        <div className={styles.signUp}>No</div>
      </div>
      
  </div> */}
</div>
</div>
      
      
     
    </div>
    </Container>
   
  );
};

export default EventEventDetailsBrowse1;
